<template>
  <div class="apartment-detail">
    <app-detail :opt="opt" ref="detail">
      <template slot="apartment">
        <div @click="clickAuthorizedRoom" class="authorizedRoomRow">
          <span v-show="!disableFlag" style="position:absolute;left:-137px;color: #F56C6C;">*</span>
          <span>{{apartmentVal}}</span>
        </div>
        <span v-if="showNotAuthRoomErrorFlag" style="color: #F56C6C;font-size: 12px;">请选择房间</span>
      </template>
    </app-detail>
    <el-dialog title="授权房间" :visible.sync="dialogVisible" :close-on-click-modal="false" :show-close="false">
      <div class="main-content authorizedBox">
        <div class="lt">
          <div class="lt-title">房源</div>
          <div class="row-box">
            <div v-for="(item,index) in list" :key="index">
              <div class="row">
                <img class="row-icon" @click="selectHouse(item,index)" :src="getCheckBoxImg(item.isAuth)">
                <div @click="clickHouse(item,index)" :style="clickHouseIndex == index ? 'color: #30BAC1;' : ''">
                  {{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="rt">
          <div class="rt-title">楼栋</div>
          <div class="row-box" v-if="buildingList && buildingList.length > 0">
            <div v-for=" (item,index) in buildingList" :key="index">
              <div class="row">
                <img class="row-icon" @click="selectBuild(item,index)" :src="getCheckBoxImg(item.isAuth)">
                <div @click="clickBuild(item,index)" :style="clickBuildIndex == index ? 'color: #30BAC1;' : ''">
                  {{item.buildName}}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="notData">暂无楼栋数据~</div>
          </div>

        </div>
        <div class="et">
          <div class="et-title">房间</div>
          <div class="row-box" v-if="roomList && roomList.length > 0">
            <div v-for="(item,index) in roomList" :key="index">
              <div class="row" @click="selectRoom(item,index)">
                <img class="row-icon" v-if="item.checked" src="../../assets/ic_select.png">
                <img class="row-icon" v-else src="../../assets/ic_unselect.png">
                <div>{{item.roomNo}}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="notData">暂无房间数据~</div>
          </div>
        </div>

      </div>
      <div class="submitClass">
        <el-button v-if="!disableFlag" @click="cancelBtn()">取消</el-button>
        <el-button v-if="!disableFlag" @click="submit()" type="primary">确定</el-button>
        <el-button v-if="disableFlag" @click="closeBtn()">关闭</el-button>
      </div>

    </el-dialog>
  </div>
</template>
<script>
  let validuserName = (rule, value, callback) => {
    let reg = /^[a-zA-Z0-9]{6,16}$/;
    if (!reg.test(value)) {
      callback(new Error("账号只能包含大小写字母与数字,长度6-16位"));
    } else {
      callback();
    }
  };
  let validpassWord = (rule, value, callback) => {
    if (value == "Aa1*******") {
      callback();
    }
    let reg = /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g;
    let reg2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/;
    if (reg.test(value)) {
      callback(new Error("密码只能包含大小写字母、数字、英文符号"));
    }
    if (!reg2.test(value)) {
      callback(new Error("密码必须最少包含字母、数字、1个大写字母、1个小写字母,长度8-16位"));
    } else {
      callback();
    }
  };
  let validID = (rule, value, callback) => {
    let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(value)) {
      callback(new Error("身份证号码不正确"));
    } else {
      callback();
    }
  };
  let validMobile = (rule, value, callback) => {
    let reg = /^1[3-9](\d){9}$/;
    if (!reg.test(value)) {
      callback(new Error("手机号码格式不对"));
    } else {
      callback();
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        dialogVisible: false,
        apartmentVal: "",
        authAccount: 0,
        clickHouseIndex: 0,
        clickBuildIndex: 0,
        buildingList: [],
        list: [],
        houseList: [],
        roomList: [],
        houseKeeperId: '',
        disableFlag: false,
        landlordAuthRelList: [],
        showNotAuthRoomErrorFlag: false,
        cacheList: '[]',
        id: "",
        opt: {
          title: "房管员详情",
          form: [{
              label: "账号",
              key: "username",
              isCol50: true,
              isWidth67: true,
              maxlength: 16,
              rules: [{
                  required: true,
                  trigger: "blur",
                  validator: validuserName,
                },
                {
                  min: 6,
                  max: 12,
                  message: "长度在 6 到 12 个字符",
                  trigger: "blur"
                }
              ]
            },
            {
              label: "密码",
              key: "password",
              isCol50: true,
              isWidth67: true,
              maxlength: 16,
              type: "password",
              only: ["add", "edit"],
              rules: [{
                required: true,
                validator: validpassWord,
                trigger: "blur",
              }]
            },
            {
              label: "姓名",
              key: "realname",
              isCol50: true,
              isWidth67: true,
              rules: [{
                required: true
              }]
            },
            {
              label: "性别",
              key: "gender",
              isCol50: true,
              isWidth67: true,
              type: "select",
              value: 1,
              opt: {
                list: [{
                    value: 1,
                    label: "男"
                  },
                  {
                    value: 2,
                    label: "女"
                  }
                ]
              }
            },
            {
              label: "联系电话",
              key: "phonenum",
              isCol50: true,
              isWidth67: true,
              maxlength: 11,
              rules: [{
                required: true,
                validator: validMobile,
                trigger: "blur"
              }]
            },
            {
              label: "选择角色",
              key: "housekeeperRoleId",
              type: "select",
              isCol50: true,
              isWidth67: true,
              opt: {
                list: []
              },
              rules: [{
                required: true,
                trigger: "blur",
              }]
            },
            {

              label: "授权房源楼栋房间",
              type: "slot",
              key: "apartmentVal",
              opt: {
                slotName: 'apartment'
              },
              // rules: [{
              //   required: true,
              //   trigger: "blur"
              // }],
            },
            // {
            //   label: "身份证正面",
            //   key: "idCardPic1",
            //   type: "img",
            //   only: "show"
            // },
            // {
            //   label: "身份证背面",
            //   key: "idCardPic2",
            //   type: "img",
            //   only: "show"
            // },
            // {
            //   label: "上传人脸头像",
            //   key: "faceUrl",
            //   type: "img",
            //   only: "show"
            // },
            // {
            //   label: "身份证正面",
            //   key: "idCardPic11",
            //   type: "upload-img-only",
            //   only: ["add", "edit"],
            //   // rules: [{ required: true }],
            //   opt: {
            //     url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
            //     tip: "上传身份证正面",
            //     upload(file) {
            //       console.log(file);
            //       return true;
            //     },
            //     remove(file, fileList) {
            //       return true;
            //     }
            //   }
            // },
            // {
            //   label: "身份证背面",
            //   key: "idCardPic22",
            //   type: "upload-img-only",
            //   only: ["add", "edit"],
            //   // rules: [{ required: true }],
            //   opt: {
            //     url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
            //     tip: "上传身份证背面面",
            //     upload(file) {
            //       console.log(file);
            //       return true;
            //     },
            //     remove(file, fileList) {
            //       return true;
            //     }
            //   }
            // },
            {
              label: "上传人脸头像",
              key: "picUrlList",
              type: "upload-img-only-one",
              only: ["add", "edit"],
              // rules: [{ required: true }],
              opt: {
                url: _this.UPLOAD_URL + "/user-service/weChat/uploadPic",
                tip: "上传人脸头像",
                alt: "上传人脸头像后,该房管员可在授权的房源人脸识别门禁机上进行人脸识别开门禁",
                upload(file) {
                  console.log(file);
                  return true;
                },
                remove(file, fileList) {
                  return true;
                }
              }
            },
            {
              label: "账号状态",
              key: "userstate",
              type: "switch"
            }
          ],
          buttons: [{
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              console.log(form);
              let dto = {
                username: form.username,
                realname: form.realname,
                gender: form.gender,
                phonenum: form.phonenum,
                // idCardPic1: form.idCardPic11.length>0?form.idCardPic11[0].url:'',
                // idCardPic2: form.idCardPic22.length>0?form.idCardPic22[0].url:'',
                userstate: form.userstate ? 2 : 3,
                landlordAuthRelList: _this.landlordAuthRelList,
                housekeeperRoleId: form.housekeeperRoleId,
              };
              if (_this.landlordAuthRelList.length == 0) {
                _this.showNotAuthRoomErrorFlag = true;
                return
              } else {
                _this.showNotAuthRoomErrorFlag = false;
              }
              dto.faceUrl = form.picUrlList.length > 0 ? form.picUrlList[0].url : "";
              if (form.password != _this.oldPassword) {
                dto.password = form.password;
              } else {
                dto.password = "";
              }
              let url = "user-service/subAccount/add";
              let message = "添加子账户成功";
              if (_this.id) {
                url = "user-service/subAccount/modify";
                dto.userId = _this.id;
                message = "编辑子账户成功";
                if (form.username == form.password) {
                  _this.$message({
                    message: "账号和密码不能相同",
                    type: "warning"
                  });
                  return false;
                }
              }
              _this
                .post(url, dto, {
                  isUseResponse: true
                })
                .then(function (res) {
                  if (res.data.code == 0) {
                    _this.$message({
                      showClose: true,
                      message: message,
                      type: "success"
                    });
                    _this.$back();
                  }
                });
            }
          }],
          get(opt) {
            let dto = {
              userId: opt.id
            };
            _this.post("user-service/subAccount/get/v2", dto).then(data => {
              data.city = Number(data.city);
              data.userstate = data.userstate == 2 ? true : false;
              data.password = "Aa1*******";
              _this.oldPassword = data.password
              // data.idCardPic11 = [{ url: data.idCardPic1 }];
              // data.idCardPic22 = [{ url: data.idCardPic2 }];
              if (data.faceUrl) {
                data.picUrlList = [{
                  url: data.faceUrl
                }];
              } else {
                data.picUrlList = []
              }
              _this.landlordAuthRelList = data.landlordAuthRelList;

              if (_this.landlordAuthRelList.length == 0) {
                _this.showNotAuthRoomErrorFlag = true;
              } else {
                _this.showNotAuthRoomErrorFlag = false;
              }
              opt.cb(data);
            });
          }
        }
      };
    },
    created: function () {
      console.log("account-detail created!!");
    },
    activated() {
      this.$refs.detail.title = ["添加房管员", "编辑房管员", "房管员详情"][{
        add: 0,
        edit: 1,
        show: 2
      } [this.$route.params.type]];
      if (this.$route.params.id != "0") {
        this.id = this.$route.params.id;
        this.opt.form[0]["disabled"] = true;
      } else {
        this.id = "";
        this.opt.form[0]["disabled"] = false;
      }
      if (this.$route.params.type == 'show') {
        this.disableFlag = true;
      } else {
        this.disableFlag = false;
      }
      this.apartmentVal = '';
      this.landlordAuthRelList = [];
      this.showNotAuthRoomErrorFlag = false;
      this.list = [];
      this.houseList = [];
      this.cacheList = '[]';
      this.getHousekeeperRole();
      this.getHouseData();
    },
    deactivated() {
      this.dialogVisible = false;
    },
    methods: {
      // 获取角色
      getHousekeeperRole() {
        this.post("user-service/user/v1/housekeeper-role/list", {
          roleType: '3'
        }, {
          contentType: "application/x-www-form-urlencoded",
        }).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/roleName/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.opt.form.forEach(data => {
            if (data.key === "housekeeperRoleId") {
              let roleListArray = [];
              data.opt.list = res;
            }
          })
        })
      },
      //获取房源列表
      getApartBuildRooms() {
        return new Promise((resolve, reject) => {
          this.post("/landlord-service/house/v1/apartment/query-all-house-tree", {
            //后台要求传空字符串
            apartmentId: ''
          }).then(res => {
            this.list = res || []
            resolve(this.list)
          }).catch(err => {
            resolve([])
          })
        })
      },
      initCheckData() {
        new Promise(async (resolve, reject) => {
          let authList = await this.getAuthData()
          if (authList.length > 0) {
            this.list.forEach(houseItem => {
              //处理房源的勾选
              if (houseItem.buildingList && houseItem.buildingList.length > 0) {
                let findAuthHouse = authList.find(authItem => authItem.id == houseItem.id)
                if (findAuthHouse && findAuthHouse.buildingList.length > 0) {
                  let authBuildList = findAuthHouse.buildingList
                  if (authBuildList.length < houseItem.buildingList.length) {
                    houseItem.isAuth = 2
                  } else {
                    let findExistRoomHalf = houseItem.buildingList.find(item => {
                      let findAuthBuild = authBuildList.find(authItem => authItem.buildCode == item.buildCode)
                      return findAuthBuild.roomList.length < item.roomList.length
                    })
                    houseItem.isAuth = findExistRoomHalf ? 2 : 1
                  }
                } else {
                  houseItem.isAuth = 0
                }
                //处理楼栋的勾选
                houseItem.buildingList.forEach(buildItem => {
                  if (findAuthHouse && findAuthHouse.buildingList.length > 0) {
                    let findAuthBuild = findAuthHouse.buildingList.find(authItem => authItem.buildCode ==
                      buildItem.buildCode)
                    if (findAuthBuild && findAuthBuild.roomList.length > 0) {
                      let authRoomList = findAuthBuild.roomList
                      buildItem.isAuth = authRoomList.length < buildItem.roomList.length ? 2 : 1
                    } else {
                      buildItem.isAuth = 0
                    }
                    //处理房间的勾选
                    buildItem.roomList.forEach(roomItem => {
                      if (findAuthBuild && findAuthBuild.roomList.length > 0) {
                        let findAuthRoom = findAuthBuild.roomList.find(authItem => authItem.id ==
                          roomItem.id)
                        roomItem.checked = findAuthRoom ? true : false
                      } else {
                        roomItem.checked = false
                      }
                    })
                  } else {
                    buildItem.isAuth = 0;
                    // 解决选择了房间，点击取消，刚才选择的数据还在问题
                    buildItem.roomList.forEach(it => {
                      it.checked = false;
                    })

                  }
                })
              } else {
                houseItem.isAuth = 0
              }
            })
          } else {

            //添加 选择房间后取消再次展示弹框数据初始化
            if (this.houseList.length == 0) {
              this.list.forEach((houseItem, houseIndex) => {
                houseItem.isAuth = 0;
                if (houseItem.buildingList && houseItem.buildingList.length > 0) {
                  houseItem.buildingList.forEach((buildItem, buildIndex) => {
                    buildItem.isAuth = 0;
                    if (buildItem.roomList && buildItem.roomList.length > 0) {
                      buildItem.roomList.forEach((roomItem, roomIndex) => {
                        roomItem.checked = false;
                      })
                    }
                  })
                }
              })
            }

          }
          this.saveCacheHouses();
          this.handleApartmentVal(this.list);
          resolve(this.list)
        })
      },

      getAuthData() {
        return new Promise((resolve, reject) => {
          if (!this.id) {
            resolve([])
            return
          }
          let dto = {
            houseKeeperId: this.id
          }
          this.post(`/landlord-service/house/v1/apartment/query-all-house-keeper-tree`, dto, {
            isUseResponse: true
          }).then(res => {
            if (res.data.code == 0) {
              let list = res.data.data || []
              resolve(list)
            } else {
              resolve([])
            }
          }).catch(err => {
            resolve([])
          })
        })
      },

      // 获取房源信息
      getHouseData() {
        this.getApartBuildRooms().then(res => {
          if (this.houseList.length > 0) {
            this.list = this.houseList;
          } else {
            this.list = res
            this.clickHouseIndex = 0;
            this.clickBuildIndex = 0;
            this.buildingList = this.list[0].buildingList ? this.list[0].buildingList : [];
            this.roomList = this.buildingList[0].roomList ? this.buildingList[0].roomList : [];
            this.initCheckData();
          }
        })

      },

      // 点击房源
      clickHouse(item, index) {
        new Promise((resolve, reject) => {
          this.clickHouseIndex = index;
          this.clickBuildIndex = 0;
          this.buildingList = this.list[index].buildingList ? this.list[index].buildingList : [];
          this.roomList = this.buildingList[0].roomList ? this.buildingList[0].roomList : [];
        })
      },

      // 选择房源
      selectHouse(item, index) {
        if (this.disableFlag) {
          return
        }
        new Promise((resolve, reject) => {
          //房源
          let isAuth = this.list[index].isAuth
          let newIsAuth = isAuth == 2 || isAuth == 1 ? 0 : 1
          this.list[index].isAuth = newIsAuth
          //楼栋
          let buildingList = this.list[index].buildingList
          if (buildingList && buildingList.length > 0) {
            buildingList.forEach((item, index) => {
              item.isAuth = newIsAuth
              //房间
              if (item.roomList && item.roomList.length > 0) {
                item.roomList.forEach((roomItem, roomIndex) => {
                  roomItem.checked = item.isAuth == 1
                })
              }
            })
          }
        })
        this.$forceUpdate();
      },

      //点击楼栋
      clickBuild(item, index) {
        new Promise((resolve, reject) => {
          this.clickBuildIndex = index;
          this.roomList = this.buildingList[index].roomList ? this.buildingList[index].roomList : [];
        })

      },

      // 选择楼栋
      selectBuild(item, index) {
        if (this.disableFlag) {
          return
        }
        new Promise((resolve, reject) => {
          let clickHouseIndex = this.clickHouseIndex
          let isHouseAuth = this.list[this.clickHouseIndex].isAuth
          let len = this.buildingList.length
          let isAuth = this.buildingList[index].isAuth
          let newIsAuth = isAuth == 2 || isAuth == 1 ? 0 : 1
          this.buildingList[index].isAuth = newIsAuth

          //房源
          let selectCount = 0
          this.buildingList.forEach((item, index) => {
            if (item.isAuth == 1) {
              selectCount++
            }
          })
          isHouseAuth = selectCount == 0 ? 0 : (selectCount < len ? 2 : 1)
          this.list[this.clickHouseIndex].isAuth = isHouseAuth
          //房间
          let roomList = this.buildingList[index].roomList
          if (roomList && roomList.length > 0) {
            roomList.forEach((item, index) => {
              item.checked = newIsAuth == 1
            })
          }
        })
        this.$forceUpdate();
      },

      //选择房间
      selectRoom(item, index) {
        if (this.disableFlag) {
          return
        }
        new Promise((resolve, reject) => {
          this.roomList = this.buildingList[this.clickBuildIndex].roomList
          let newChecked = this.roomList[index].checked ? false : true
          this.roomList[index].checked = newChecked
          //楼栋
          let selectCount = 0
          this.roomList.forEach((item, index) => {
            if (item.checked) {
              selectCount++
            }
          })
          let isBuildAuth = this.buildingList[this.clickBuildIndex].isAuth
          isBuildAuth = selectCount == 0 ? 0 : (selectCount < this.roomList.length ? 2 : 1)
          this.buildingList[this.clickBuildIndex].isAuth = isBuildAuth
          //房源
          let isHouseAuth = this.list[this.clickHouseIndex].isAuth
          let selectBuildCount = 0
          let existHalf = false
          this.buildingList.forEach((item, index) => {
            if (item.isAuth == 1) {
              selectBuildCount++
            }
            if (item.isAuth == 2) {
              existHalf = true
            }
          })
          if (selectBuildCount == 0) {
            isHouseAuth = existHalf ? 2 : 0
          } else if (selectBuildCount < this.buildingList.length) {
            isHouseAuth = 2
          } else {
            isHouseAuth = 1
          }
          this.list[this.clickHouseIndex].isAuth = isHouseAuth
        })
        this.$forceUpdate()
      },

      // 确定
      submit() {
        this.houseList = this.list ? this.list : [];
        let landlordId = this.cache.getLS("userInfo")["userId"]
        if (this.houseList.length > 0) {
          this.landlordAuthRelList = [];
          this.houseList.forEach(item => {
            if (item.isAuth == 1 || item.isAuth == 2) {
              item.buildingList.forEach(it => {
                if (it.isAuth == 1 || it.isAuth == 2) {
                  it.roomList.forEach(roomItem => {
                    if (roomItem.checked) {
                      this.landlordAuthRelList.push({
                        apartmentId: item.id,
                        buildingNo: it.buildCode,
                        roomId: roomItem.id,
                        landlordAgentId: this.id, //房管员ID
                        landlordId: landlordId,
                        //后台要求该这段写死，该字段无业务意义
                        authInfo: 0,
                      })
                    }
                  })
                }
              })
            }
          })
        } else { //点击详情后点击修改  未重新授权房源
          let list = [];
          this.landlordAuthRelList.forEach(item => {
            list.push({
              apartmentId: item.apartmentId,
              buildingNo: item.buildingNo,
              roomId: item.roomId,
              landlordAgentId: this.id,
              landlordId: landlordId,
              //后台要求该这段写死，该字段无业务意义
              authInfo: 0,
            })
          })
          this.landlordAuthRelList = list;
        }
        this.saveCacheHouses();
        if (this.landlordAuthRelList.length == 0) {
          this.showNotAuthRoomErrorFlag = true;
        } else {
          this.showNotAuthRoomErrorFlag = false;
        }
        this.dialogVisible = false
        this.handleApartmentVal(this.list);
      },
      // 点击授权房源
      clickAuthorizedRoom() {
        this.dialogVisible = true;
        this.buildingList = this.list[this.clickHouseIndex].buildingList || [];
        this.roomList = this.buildingList[this.clickBuildIndex].roomList || this.buildingList[this.clickBuildIndex]
          .roomList;
      },
      cancelBtn() {
        this.dialogVisible = false;
        this.list = JSON.parse(this.cacheList)
      },
      closeBtn() {
        this.dialogVisible = false;
      },

      //将选中确定的数据保存起来 
      saveCacheHouses() {
        new Promise((resolve, reject) => {
          this.cacheList = JSON.stringify(this.list)
        })
      },
      //将选择的房间数据显示在页面上
      handleApartmentVal(list) {
        let apartmentText = '';
        // let list = list || [];
        let houseList = list || [];
        if (houseList.length > 0) {
          houseList.forEach((houseItem, houseIndex) => {
            if (houseItem.buildingList && houseItem.buildingList.length > 0) {
              houseItem.buildingList.forEach((buildItem, buildIndex) => {
                if (buildItem.roomList && buildItem.roomList.length > 0) {
                  buildItem.roomList.forEach((roomItem, roomIndex) => {
                    if (roomItem.checked) {
                      if (apartmentText) {
                        apartmentText = apartmentText + '、' + houseItem.name + '/' + buildItem.buildName +
                          '/' + roomItem.roomNo;
                      } else {
                        apartmentText = houseItem.name + '/' + buildItem.buildName + '/' + roomItem.roomNo;
                      }
                    }
                  })
                }
              })
            }
          })
          this.apartmentVal = apartmentText;
        } else {
          this.apartmentVal = '';
        }

      },

      getCheckBoxImg(val) {
        return require(!val || val == 0 ? '../../assets/ic_unselect.png' :
          val == 1 ? '../../assets/ic_select.png' : '../../assets/ic_select_half.png')
      },

    }
  };
</script>
<style lang="scss" scoped>
  .el-upload--picture-card {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 148px;
    min-height: 148px;
    width: auto;
    height: auto;
    line-height: 146px;
    vertical-align: top;
  }

  .authorizedBox {
    height: 620px;
  }

  .main-content {
    font-size: 15px;
    display: flex;
    margin-left: 20px;
    margin-top: 10px;
  }

  .lt,
  .rt,
  .et {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 620px;
    overflow: auto;
  }

  .lt,
  .rt {
    border-right: 1px solid #eeeeee;
  }

  .lt-title,
  .rt-title,
  .et-title {
    font-size: 15px;
    padding-left: 20px;
  }

  .row-box {
    height: 520px;
    overflow-y: auto;
  }

  .row {
    padding: 4px 18px;
    display: flex;
    cursor: default;
  }

  .row-icon {
    width: 17px;
    height: 17px;
    top: 3px;
    position: relative;
    padding-right: 12px;
    cursor: pointer;
  }



  .notData {
    margin-top: 50px;
    margin-left: 50px;
    text-align: center;
  }

  .submitClass {
    text-align: center;
    padding-bottom: 20px;
  }

  .authorizedRoomRow {
    color: #606266;
    // width: 100%;
    height: 35px;
    border: 1px solid #DCDFE6;
    cursor: pointer;
    border-radius: 3px;
    overflow: hidden;
    box-sizing: border-box;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;

    span {
      height: 35px;
      margin-left: 5px;
    }
  }

  /deep/.el-dialog {
    width: 640px;
    height: 760px;
  }

  /deep/.el-dialog__body {
    padding: 0;
  }
</style>